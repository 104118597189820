import { registerLicense, L10n } from "@syncfusion/ej2-base";
import {
	closest,
	remove,
	addClass,
	Internationalization,
	extend,
	isNullOrUndefined,
} from "@syncfusion/ej2-base";
import {
	Schedule,
	Day,
	WorkWeek,
	ScheduleModel,
	Week,
	Month,
	TimelineViews,
	Year,
	TimelineMonth,
	Resize,
	DragAndDrop,
	ResourcesModel,
	ResourceDetails,
	Agenda,
	PopupOpenEventArgs,
	EJ2Instance,
	CellClickEventArgs,
	CurrentAction,
	EventRenderedArgs,
} from "@syncfusion/ej2-schedule";
import $, { get } from "jquery";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DragAndDropEventArgs, TreeView } from "@syncfusion/ej2-navigations";
import { TextBox } from "@syncfusion/ej2-inputs";
import { Button, CheckBox } from "@syncfusion/ej2-buttons";
import moment from "moment";
import { Query, Predicate } from "@syncfusion/ej2-data";

interface SchedulerOptions {
	wrapper: string | HTMLElement;
}

// tslint:disable-next-line:max-func-body-length
interface TemplateFunction extends Window {
	getHeaderDetails: Function;
	getHeaderStyles?: (data: Record<string, any>) => string;
	getEventType?: Function;
	getResourceData(data: Record<string, any>): Record<string, any>;
}
// global.d.ts
declare let frappe: any;

let dataSource: object[] = [];
let dataEvents: object[] = [];

class ShiftPlanner {
	constructor({ wrapper }: SchedulerOptions) {
		try {
			if (typeof $ === "undefined") {
				throw new Error("jQuery is not defined.");
			}

			console.log("wraaaper", wrapper);
			
			registerLicense("Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9edHRcQmlcWEJ0WEA=");
			
		} catch (e) {			
			console.error(e);
		}
	}
	cleanscheduler() {
		console.log("cleanscheduler");
		$("#scheduler").empty();
		$(".property-panel-table").empty();
		$(".resource-panel-table").empty();
	}
	async resourceShift() {
		// const resource_shift = await (frappe as any).db.get_list(
		// 	"Shift Description",
		// 	{ fields: ["name", "color_shift"], limit: 0 }
		// );
		// const data_shift_resource = resource_shift.map((item: any) => {
		// 	let name_lower = item.name.toLowerCase();
		// 	let name_formated = name_lower.replaceAll(" ", "_");
		// 	return {
		// 		Name: item.name,
		// 		Appointment: item.name,
		// 		Color: item.color_shift,
		// 		RoomId: name_formated,
		// 		Type: "Property",
		// 	};
		// });

		return [];		
	}
	async resourceMachine() {
		// @ts-ignore
		const data_machine = await (frappe as any).db.get_list("Data Model", {
			fields: ["name", "description", "color"],
			limit: 0,
		});
		const data_machine_resource = data_machine.map((item: any) => {
			let name_lower = item.description.toLowerCase();
			let name_formated = name_lower.replaceAll(" ", "_");
			return {
				Name: item.description,
				NameResource: item.description,
				Color: item.color,
				ResourceId: name_formated,
				Type: "Resource",
				Id: item.name,
			};
		});
		return Promise.all(data_machine_resource);
	}
	/**
	 * This method gets the list of resources, both machines and shifts.
	 * It first gets the list of machines from the database, and then
	 * gets the list of shifts from the database.
	 * It then merges the two lists together, and maps over the list
	 * to create a new list with the properties 'id', 'name', 'color',
	 * 'roomId', 'type', and 'Id'.
	 * The 'id' property is the roomId for shifts and the resourceId for machines.
	 * The 'name' property is the description for machines and the name for shifts.
	 * The 'color' property is the color for machines and shifts.
	 * The 'roomId' property is the roomId for shifts and the resourceId for machines.
	 * The 'type' property is either 'Resource' for machines or 'Property' for shifts.
	 * The 'Id' property is the id for machines and shifts.
	 * @returns {Promise<Object[]>} The list of resources.
	 */
	async resourceData(): Promise<Object[]> {
		const data_machine_resource = await this.resourceMachine();
		const data_shift_resource = await this.resourceShift();
		const merge_resource = [
			...data_machine_resource,
			...data_shift_resource,
		];
		const merge_resource_id = merge_resource.map(
			(item: any, index: number) => {
				return {
					...item,
				};
			}
		);
		const list_resource = merge_resource_id.map((item: any) => {
			return {
				id: item.Type === "Resource" ? item.ResourceId : item.RoomId,
				name: item.Name,
				color: item.Color,
				roomId:
					item.Type === "Resource" ? item.ResourceId : item.RoomId,
				type: item.Type,
				Id: item.Id,
			};
		});

		return list_resource;
	}
	get toolbarClick(): Object[] {
		return [
			{
				name: "Previous",
				align: "Left",
			},
			{
				name: "Next",
				align: "Left",
			},
			{
				name: "DateRangeText",
				align: "Left",
			},
			{
				text: "Save",
				id: "save",
				cssClass: "e-icons e-save",
				align: "Left",
				click: () => this.save_shift(),
			},
			{
				text: "New",
				id: "new",
				cssClass: "e-icons e-plus",
				align: "Left",
				click: () => this.new_shift(),
			},
			{
				text: "Delete",
				id: "delete",
				align: "Left",
				cssClass: "e-icons e-trash",
				click: () => this.delete_shift(),
			},
			{
				text: "Select Shift",
				id: "select",
				cssClass: "e-icons e-edit",
				align: "Left",
				click: () => this.select_shift(),
			},
			{
				text: "Change",
				id: "change",
				cssClass: "e-icons e-refresh",
				align: "Left",
				click: () => this.change_shift("resource"),
			},
			{
				name: "Today",
				align: "Right",
			},
			{
				name: "Views",
				align: "Right",
			},
		];
	}
	async holidays() {
		const exists = frappe.db.exists("Holidays", null, {
			country: "Brazil",
		});
		if (!exists) {
			return [];
		}
		const holiday = await frappe.db.get_doc("Holidays", null, {
			country: "Brazil",
		});
		const data_holiday = holiday.holidays.map((doc: any) => {
			const start_date = doc.start_date + "T00:00:00";
			const end_date = doc.end_date + "T00:00:00";
			return {
				Subject: doc.holiday,
				StartTime: new Date(start_date),
				EndTime: new Date(end_date),
				Location: holiday.country,
				RoomId: "holidays",
				IsAllDay: 1,
			};
		});
		// console.log("data_holiday", data_holiday);
		return data_holiday;
	}	
	
	async loadScheduler(data_shift: object[], date: Date) {
		const data_holiday = await this.holidays();
		data_shift = [...data_shift, ...data_holiday];
		const templateFunction = window as unknown as TemplateFunction;
		templateFunction.getResourceData = (data: Record<string, any>) => {
			console.log("data getResourceData", data);
			console.log(
				"resourceData",
				scheduleObj.getResourceCollections().slice(0)[0]
			);
			console.log(
				"resourceDat",
				scheduleObj.getResourceCollections().slice(-1)[0]
			);

			let resources: ResourcesModel = scheduleObj
				.getResourceCollections()
				.slice(0)[0];
			let resourceData: Record<string, any> = (
				resources.dataSource as { [key: string]: number }[]
			).filter(
				(resource: { [key: string]: number }) =>
					resource.RoomId === data.RoomId
			)[0] as Record<string, any>;
			let resource: ResourcesModel = scheduleObj
				.getResourceCollections()
				.slice(-1)[0];
			let resourceDat: Record<string, any> = (
				resource.dataSource as { [key: string]: number }[]
			).filter(
				(resource: { [key: string]: number }) =>
					resource.ResourceId === data.ResourceId
			)[0] as Record<string, any>;
			console.log(
				"resource",
				resources.dataSource,
				"data",
				data,
				resourceData
			);
			if (data.RoomId) {
				return resourceData;
			}
			return resourceDat;
		};
		templateFunction.getHeaderDetails = (data: { [key: string]: Date }) => {
			let intl: Internationalization = new Internationalization();
			return (
				intl.formatDate(data.StartTime, {
					type: "date",
					skeleton: "full",
				}) +
				" (" +
				intl.formatDate(data.StartTime, { skeleton: "hm" }) +
				" - " +
				intl.formatDate(data.EndTime, { skeleton: "hm" }) +
				")"
			);
		};

		templateFunction.getHeaderStyles = (data: Record<string, any>): string => {						
			if (data.elementType === "cell") {
				return "align-items: center; color: #919191;";
			} else if (data.elementType === "event") {
				let resourceData: Record<string, any> = templateFunction.getResourceData(data);
				if (resourceData) {
					return `background: ${resourceData.Color}; color: #FFFFFF;`;
				} else {
					console.warn("resourceData is undefined for event", data);
					return "background: #000000; color: #FFFFFF;"; // Estilo padrão ou de fallback
				}
			}
			return "";
		};
		let buttonClickActions: Function = (e: Event): void => {
			let quickPopup: HTMLElement | null = closest(
				e.target as HTMLElement,
				".e-quick-popup-wrapper"
			) as HTMLElement | null;
			if (!quickPopup) {
				console.error("quickPopup is null");
				return;
			}
			let getSlotData: Function = (): Record<string, any> => {
				let subjectElement: HTMLElement | null =
					quickPopup.querySelector("#title") as HTMLElement | null;
				if (!subjectElement) {
					console.error("subjectElement is null");
					return {};
				}
				let subject = (
					(subjectElement as EJ2Instance).ej2_instances[0] as TextBox
				).value;
				let notesElement: HTMLElement | null = quickPopup.querySelector(
					"#notes"
				) as HTMLElement | null;
				if (!notesElement) {
					console.error("notesElement is null");
					return {};
				}
				// let notes = (
				// 	(notesElement as EJ2Instance)
				// 		.ej2_instances[0] as DropDownList
				// ).value;
				let addObj: Record<string, any> = {};
				addObj.Id = scheduleObj.getEventMaxID();
				addObj.Subject = isNullOrUndefined(subject)
					? "Default title"
					: subject;
				addObj.StartTime = new Date(
					scheduleObj.activeCellsData.startTime
				);
				addObj.EndTime = new Date(scheduleObj.activeCellsData.endTime);
				addObj.IsAllDay = scheduleObj.activeCellsData.isAllDay;
				addObj.ResourceId = (
					(notesElement as EJ2Instance)
						.ej2_instances[0] as DropDownList
				).value;
				let eventTypeElement: HTMLElement | null =
					quickPopup.querySelector(
						"#eventType"
					) as HTMLElement | null;
				if (!eventTypeElement) {
					console.error("eventTypeElement is null");
					return {};
				}
				addObj.RoomId = (
					(eventTypeElement as EJ2Instance)
						.ej2_instances[0] as DropDownList
				).value;
				return addObj;
			};
			if ((e.target as HTMLElement).id === "add") {
				let addObj: Record<string, any> = getSlotData();
				scheduleObj.addEvent(addObj);
			} else if ((e.target as HTMLElement).id === "delete") {
				let eventDetails: Record<string, any> = scheduleObj
					.activeEventData.event as Record<string, any>;
				if (!eventDetails) {
					console.error("eventDetails is null");
					return;
				}
				let currentAction: CurrentAction = eventDetails.RecurrenceRule
					? "DeleteOccurrence"
					: "Delete";

				scheduleObj.deleteEvent(eventDetails, currentAction);
			} else {
				let isCellPopup: boolean = (
					quickPopup.firstElementChild as HTMLElement
				).classList.contains("e-cell-popup");
				let eventDetails: Record<string, any> = isCellPopup
					? getSlotData()
					: (scheduleObj.activeEventData.event as Record<
							string,
							any
					  >);
				if (!eventDetails) {
					console.error("eventDetails is null");
					return;
				}
				let currentAction: CurrentAction = isCellPopup ? "Add" : "Save";
				if (eventDetails.RecurrenceRule) {
					currentAction = "EditOccurrence";
				}
				scheduleObj.openEditor(eventDetails, currentAction, true);
			}
			scheduleObj.closeQuickInfoPopup();
		};

		templateFunction.getEventType = (data: { [key: string]: Date }) => {
			let resourceData: Record<string, any> = templateFunction.getResourceData(data);
			console.log("getEventType", resourceData);
			
			if (resourceData) {
				return resourceData.Name;
			} else {
				console.warn("resourceData is undefined for event", data);
				return "Unknown"; // Valor padrão ou de fallback
			}
		};

		const data_shift_resource = await this.resourceShift();
		const data_machine_resource = await this.resourceMachine();
		const list_resource = await this.resourceData();
		console.log(data_machine_resource);

		$(".property-panel-table").append(
			'<span id="property">Shift and Event Types</span>'
		);
		$(".resource-panel-table").append(
			'<span id="resources">Resources</span>'
		);
		list_resource.map((item: any) => {
			let input_checkbox = `<tr style="height: 50px"><td><input id="${item.id}" type="checkbox" /></td></tr>`;
			if (item.type == "Property") {
				$(".property-panel-table").append(input_checkbox);
			}
			if (item.type == "Resource") {
				$(".resource-panel-table").append(input_checkbox);
			}
		});
		Schedule.Inject(
			TimelineViews,
			Week,
			Year,
			TimelineMonth,
			Month,
			Resize,
			DragAndDrop,
			Agenda
		);
		// dataSource.push(...data_shift);
		const toolbar = this.toolbarClick;
		let scheduleOptions: ScheduleModel = {
			width: "100%",
			height: "800px",
			selectedDate: date,
			views: ["TimelineWeek", "Week", "TimelineMonth", "Month", "Year"],
			currentView: "Month",
			toolbarItems: toolbar,
			resources: [
				{
					field: "RoomId",
					title: "Appointment",
					name: "MeetingRoom",
					// allowMultiple: true,
					textField: "Appointment",
					idField: "RoomId",
					colorField: "Color",
					dataSource: data_shift_resource,
				},
				{
					field: "Resource",
					title: "Resource",
					name: "Resource",
					dataSource: data_machine_resource,
					textField: "NameResource",
					idField: "ResourceId",
					colorField: "Color",
				},
				{									
					name: "ResourceName",										
					idField: "Name"					
				},
			],
			eventSettings: {
				dataSource: data_shift,
			},
			quickInfoTemplates: {
				header: "#header-template",
				content: "#content-template",
				footer: "#footer-template",
			},
			actionBegin: (args) => {
				console.log("requestType", args.data);
				
				if (args.requestType === "eventRemove") {
					dataSource = dataSource.filter(
						(doc) => doc["Id"] !== args.data[0].Id
					);
					dataEvents = dataEvents.filter(
						(doc) =>
							doc["Id"] !== args.data[0].Id &&
							doc["EndTime"] === args.data[0].EndTime &&
							doc["StartTime"] === args.data[0].StartTime
					);
				}
				if (
					args.requestType === "eventCreate" ||
					args.requestType === "eventChange"
				) {
					let data = void 0;
					if (args.requestType === "eventCreate") {
						data = args.data[0];
						console.time("HidalgoTime");

						if (data && (data as any).RecurrenceRule) {
							let recurrenceSplit = (
								data as any
							).RecurrenceRule.split(";");
							let recurrence =
								recurrenceSplit[recurrenceSplit.length - 2].split(
									"="
								);
						
							let lastDate = (data as any).EndTime;
							const getDataEvent = (
								events: Record<string, any>[]
							) => {
								let diffTime = frappe.datetime.get_day_diff(
									events[events.length - 1].EndTime,
									(data as any).EndTime
								);
								if (
									events.length > 0 &&
									events[events.length - 1].EndTime !== null &&
									events[events.length - 1].EndTime !== undefined
								) {
									// se for interval - recursivo
									if (
										recurrence[0] == "INTERVAL" &&
										diffTime < 700
									) {
										let newEvent =
											scheduleObj.generateEventOccurrences(
												data as any,
												events[events.length - 1].EndTime
											);
										events.push(...newEvent);
										return getDataEvent(events);
									} else if (recurrence[0] == "UNTIL") {
										if (
											events[events.length - 1].EndTime ==
											lastDate
										) {
											return events;
										} else {
											lastDate =
												events[events.length - 1].EndTime;
											return getDataEvent(events);
										}
									} else if (recurrence[0] == "COUNT") {
										return events;
									} else {
										return events;
									}
									// se for count salvar o endtime do ultimo evento para o proximo se na proxima vez que entrar o endtime ser igual ele retorna o evento apenas
								} else {
									return events;
								}
							};
							console.log("events", data);

							let event = scheduleObj.generateEventOccurrences(
								data as any,
								new Date()
							);
							console.log("event", event);
							dataEvents.push(getDataEvent(event));
						
							console.log("data", dataEvents);
							console.timeEnd("HidalgoTime");
							dataSource.push(data as any);
						}
					} else if (args.requestType === "eventChange") {
						data = args.data;
						
						updateEvent(data, dataSource, dataEvents);
					}
				}
			},			
		
			actionComplete: (args) => {
				console.log("actionComplete", args);							

				if (args.requestType === "eventCreated") {
					let data = args.data[0];
			
					// Verifica se não há regra de recorrência
					if (!data.RecurrenceRule) {
						// Adiciona o evento ao dataSource e dataEvents
						
						dataSource.push(data);
						dataEvents.push(data);
					}
				}

				if (args.requestType === "eventChanged") {
					let data = args.data;
					
					updateEvent(data, dataSource, dataEvents);									
				}
			},			

			renderCell: (args) => {
				// renderiza as celulas
				// console.log("renderCell", args);
				if (
					args.element &&
					args.element.classList.contains("e-work-cells")
				) {
					if (args.date < new Date()) {
						args.element.setAttribute("aria-readonly", "true");
						args.element.classList.add("e-read-only-cells");
					}
				}
				if (
					args.elementType === "emptyCells" &&
					args.element &&
					args.element.classList.contains("e-resource-left-td")
				) {
					let target = args.element.querySelector(".e-resource-text");
					if (target) {
						target.innerHTML =
							'<div class="name">Rooms</div><div class="type">Type</div><div class="capacity">Capacity</div>';
					}
				}
			},
			eventRendered: (args) => {
				// renderiza o evento no scheduler e atualiza tela
				if (!args.data) {
					return;
				}
				let data = args.data;
				if (!data.StartTime) {
					return;
				}
				
				let categoryColor: string = args.data.CategoryColor as string;

				if (!args.element || !categoryColor) {
					return;
				}
				if (scheduleObj.currentView == "Agenda") {
					(
						args.element.firstChild as HTMLElement
					).style.borderLeftColor = categoryColor;
				} else {
					args.element.style.backgroundColor = categoryColor;
				}
			},
			popupOpen: (args: PopupOpenEventArgs) => {
				// @ts-ignore
				console.log("popupOpen", args);
				// let link_field = new frappe.ui.FieldGroup({fieldname: 'test', label: 'test', fieldtype: 'Link', options: 'Shift'});
				if (
					args.type === "QuickInfo" ||
					args.type === "ViewEventInfo"
				) {
					if (
						!(args.target as HTMLElement).classList.contains(
							"e-appointment"
						)
					) {
						let titleObj: TextBox = new TextBox({
							placeholder: "Title",
						});
						titleObj.appendTo(
							args.element.querySelector("#title") as HTMLElement
						);
						// args.element.querySelector('#title')?.appendChild(link_field);
						titleObj.focusIn();
						let typeObj: DropDownList = new DropDownList({
							dataSource: scheduleObj
								.getResourceCollections()
								.slice(0)[0].dataSource as Record<
								string,
								any
							>[],
							placeholder: "Choose Type",
							fields: { text: "Name", value: "RoomId" },
							index: 0,
						});
						typeObj.appendTo(
							args.element.querySelector(
								"#eventType"
							) as HTMLElement
						);
						console.log(
							"getResourceCollections",
							scheduleObj.getResourceCollections()
						);						

						const resourceCollections = scheduleObj.getResourceCollections();

						if (resourceCollections.length >= 2) {
							const machinesDataSource = resourceCollections[1].dataSource;

							// if (Array.isArray(machinesDataSource) && !machinesDataSource.some(item => item.Name === "" && item.ResourceId === "")) {
							// 	machinesDataSource.unshift({ Name: "", ResourceId: "" });
							// }
					
							const machinesObj = new DropDownList({
								dataSource: machinesDataSource,
								placeholder: "Choose Resource",
								fields: {
									text: "Name", 
									value: "ResourceId"
								},
								// value: "", 
								index: 0
							});
							
							machinesObj.appendTo("#notes");
						} else {
							console.error("resourceCollections does not have enough positions.");
						}

					}

					let moreDetailsBtn: HTMLButtonElement =
						args.element.querySelector(
							"#more-details"
						) as HTMLButtonElement;
					if (moreDetailsBtn) {
						let moreObj: Button = new Button({
							content: "More Details",
							cssClass: "e-flat",
							isPrimary: (
								args.element.firstElementChild as HTMLElement
							).classList.contains("e-event-popup"),
						});
						moreObj.appendTo(moreDetailsBtn);
						moreDetailsBtn.onclick = (e: Event) => {
							buttonClickActions(e);
						};
					}
					let addBtn: HTMLButtonElement = args.element.querySelector(
						"#add"
					) as HTMLButtonElement;
					if (addBtn) {
						new Button(
							{
								content: "Add",
								cssClass: "e-flat",
								isPrimary: true,
							},
							addBtn
						);
						addBtn.onclick = (e: Event) => {
							buttonClickActions(e);
						};
					}
					let deleteBtn: HTMLButtonElement =
						args.element.querySelector(
							"#delete"
						) as HTMLButtonElement;
					if (deleteBtn) {
						new Button(
							{ content: "Delete", cssClass: "e-flat" },
							deleteBtn
						);
						deleteBtn.onclick = (e: Event) => {
							buttonClickActions(e);
						};
					}
				}
			},
		};
		let scheduleObj: Schedule = new Schedule(
			scheduleOptions,
			(document as any).getElementById("scheduler")
		);
		let orwers = list_resource.map((resource: any) => {
			let owner: CheckBox = new CheckBox({
				cssClass: `${resource.id}`,
				value: `${resource.roomId}`,
				label: `${resource.name}`,
				checked: true,
				change:
					resource.type === "Resource" ? onChangeResource : onChange,
			});
			owner.appendTo(`#${resource.id}`);
			return owner;
		});
		console.log("orwers", orwers);
		let predicate: Predicate | undefined;
		function onChangeResource(): void {
			predicate = undefined;
			let checkBoxes: CheckBox[] = orwers;
			checkBoxes.forEach((checkBoxObj: CheckBox) => {
				if (checkBoxObj.checked) {
					if (predicate) {
						predicate = predicate.or(
							"ResourceId",
							"equal",
							checkBoxObj.value
						);
					} else {
						predicate = new Predicate(
							"ResourceId",
							"equal",
							checkBoxObj.value
						).or("ResourceId", "equal", null);
					}
				}
			});
			if (predicate) {
				console.log("predicate", predicate);
				scheduleObj.eventSettings.query = new Query().where(predicate);
			} else {
				console.log("null");
				// scheduleObj.eventSettings.query = new Query([]);
			}
		}
		function onChange(): void {
			predicate = undefined;
			let checkBoxes: CheckBox[] = orwers;
			checkBoxes.forEach((checkBoxObj: CheckBox) => {
				if (checkBoxObj.checked) {
					if (predicate) {
						predicate = predicate.or(
							"RoomId",
							"equal",
							checkBoxObj.value
						);
					} else {
						predicate = new Predicate(
							"RoomId",
							"equal",
							checkBoxObj.value
						).or("RoomId", "equal", null);
					}
				}
			});
			if (predicate) {
				scheduleObj.eventSettings.query = new Query().where(predicate);
				// console.log(
				// 	"predicate",
				// 	(scheduleObj.eventSettings.query = new Query().where(
				// 		predicate
				// 	))
				// );
			} else {
				console.log("null");
				// scheduleObj.eventSettings.query = new Query([]);
			}
		}
		function updateEvent(data: any, dataSource: any[], dataEvents: any[]): void {
			let exists = dataSource.filter((doc) => doc["Id"] === data.Id);
			let existsEvent = dataEvents.filter((doc) => doc["Id"] === data.Id);
		
			if (existsEvent) {
				dataEvents.map((doc) => {
					if (
						doc["Id"] === data.Id &&
						doc["EndTime"] === data.EndTime &&
						doc["StartTime"] === data.StartTime
					) {
						doc["Subject"] = data.Subject;
						doc["Location"] = data.Location;
						doc["Description"] = data.Description;
						doc["IsAllDay"] = data.IsAllDay;
						doc["RecurrenceRule"] = data.RecurrenceRule;
						doc["RoomId"] = data.RoomId;
						doc["ResourceId"] = data.ResourceId;
						doc["Name"] = data.Name;
					}
				});
			}
		
			if (exists && dataSource.length) {
				dataSource.map((doc) => {
					if (doc["Id"] === data.Id) {
						doc["StartTime"] = data.StartTime;
						doc["EndTime"] = data.EndTime;
						doc["IsAllDay"] = data.IsAllDay;
						doc["Subject"] = data.Subject;
						doc["Location"] = data.Location;
						doc["Description"] = data.Description;
						doc["RecurrenceRule"] = data.RecurrenceRule;
						doc["RoomId"] = data.RoomId;
						doc["ResourceId"] = data.ResourceId;
						doc["Name"] = data.Name;
					}
				});
			}
		}		
	}
	async loadSchedule(
		data_shift: object[],
		date: Date,
		data_machine: object[]
	) {
		Schedule.Inject(
			Day,
			WorkWeek,
			Month,
			TimelineViews,
			Resize,
			DragAndDrop
		);
		const data_holiday = await this.holidays();
		const data_shift_resource = await this.resourceShift();
		data_shift = [...data_shift, ...data_holiday];
		// const list_resource = await this.resourceData();
		// console.log("DATA", data_shift);
		let resourceHeaderTemplate = (resource: { [key: string]: any }) => {
			console.log("resource", resource);
			let site =  'http://192.168.101.81:8080' //frappe.boot.sitename;
			if (resource.resource.field == "ConferenceId")
				return `<div style="display: flex; align-items: center;"><img src="${resource.resourceData.Image}" style="width: 60px; height: auto;" /><div><a style="color: ${resource.resourceData.Color}; margin-left: 5px;" href="${site}/app/data-model/${resource.resourceData.Id}" target="_blank">${resource.resourceData.Text}</a></div></div>`;
		};
		let monthEventTemplate: string =
			'<div class="subject">${Subject}</div>';
		let scheduleOptions: ScheduleModel = {
			width: "100%",
			height: "650px",
			currentView: "WorkWeek",
			selectedDate: date,
			toolbarItems: [
				{
					name: "Previous",
					align: "Left",
				},
				{
					name: "Next",
					align: "Left",
				},
				{
					name: "DateRangeText",
					align: "Left",
				},
				{
					text: "Change",
					id: "change",
					cssClass: "e-icons e-refresh",
					align: "Left",
					click: () => this.change_shift("no"),
				},
				{
					name: "Views",
					align: "Right",
				},
			],
			resourceHeaderTemplate: resourceHeaderTemplate,
			views: [
				{ option: "Day" },
				{ option: "WorkWeek" },
				{ option: "Month", eventTemplate: monthEventTemplate },
				{ option: "TimelineWeek" },
			],
			group: {
				allowGroupEdit: true,
				resources: ["Conferences"],
			},
			resources: [
				{
					field: "ConferenceId",
					title: "Data Model",
					name: "Conferences",
					allowMultiple: true,
					dataSource: data_machine,
					textField: "Text",
					idField: "Id",
					colorField: "Color",
				},
				{
					field: "RoomId",
					title: "Shift",
					name: "MeetingRoom",
					allowMultiple: true,
					dataSource: data_shift_resource,
					textField: "Name",
					idField: "RoomId",
					colorField: "Color",
				},
			],
			eventSettings: {
				dataSource: data_shift as any,
				fields: {
					subject: { title: "Title", name: "Subject" },
					description: { title: "Summary", name: "Description" },
					startTime: { title: "From", name: "StartTime" },
					endTime: { title: "To", name: "EndTime" },
				},
			},
			// popupOpen: (args) => {
			// 	console.log("args", args);
			// },
		};
		// let orwers: CheckBox[] = [];
		// list_resource.forEach((resource: any) => {
		// 	if (resource.type == "Resource" && resource.Id) {
		// 		let owner: CheckBox = new CheckBox({
		// 			cssClass: `${resource.Id}`,
		// 			value: `${resource.Id}`,
		// 			label: `${resource.name}`,
		// 			checked: true,
		// 			change: onChange,
		// 		});
		// 		owner.appendTo(`#${resource.id}`);
		// 		orwers.push(owner);
		// 	}
		// });
		// console.log("orwers", orwers);
		// let predicate: Predicate | undefined;
		// function onChange(): void {
		// 	if (!orwers || orwers.length == 0) {
		// 		return;
		// 	}
		// 	predicate = undefined;
		// 	let checks: string[] = [];
		// 	orwers.forEach((checkBoxObj: CheckBox) => {
		// 		if (checkBoxObj.checked) {
		// 			checks.push(checkBoxObj.value);
		// 			if (predicate) {
		// 				predicate = predicate.or(
		// 					"Id",
		// 					"equal",
		// 					checkBoxObj.value
		// 				);
		// 			} else {
		// 				predicate = new Predicate(
		// 					"Id",
		// 					"equal",
		// 					checkBoxObj.value
		// 				);
		// 			}
		// 		}
		// 	});
		// 	if (predicate) {
		// 		scheduleObj.eventSettings.query = new Query().where(predicate);
		// 		console.log("checks", checks);
		// 		let resourceHeaderTemplate = (resource: {
		// 			[key: string]: any;
		// 		}) => {
		// 			console.log("resource", resource);

		// 			if (checks.includes(resource.resourceData.Id)) {
		// 				return `<div style="display: flex; align-items: center;"><img src="${resource.resourceData.Image}" style="width: 60px; height: auto;" /><div><a style="color: ${resource.resourceData.Color}; margin-left: 5px;" href="https://nxerp.grvflex.com.br/app/data-model/${resource.resourceData.Id}" target="_blank">${resource.resourceData.Text}</a></div></div>`;
		// 			}
		// 		};
		// 		scheduleObj.resourceHeaderTemplate = resourceHeaderTemplate;
		// 		scheduleObj.refreshLayout();
		// 	}
		// }
		let scheduleObj: Schedule = new Schedule(scheduleOptions);
		console.log("scheduleObj", scheduleObj);
		scheduleObj.appendTo("#scheduler");
	}
	loadData() {
		return dataEvents;
	}
	async change_shift(type: string) {
		if (type == "resource") {
			$(".property-section.border").prop("hidden", true);
			$("#control-section").removeClass("col-lg-9");
			$("#control-section").addClass("col-lg-12");
			const data_machine = await (frappe as any).db.get_list(
				"Data Model",
				{
					fields: [
						"description",
						"name",
						"color",
						"image_data_model",
					],
					limit: 0,
				}
			);
			const data = data_machine.map((doc) => {
				return {
					Text: (doc as any).description,
					Color: (doc as any).color,
					Id: (doc as any).name,
					Image: doc.image_data_model,
				};
			});
			console.log("data", data);
			this.setDataResource([], data);
		} else {
			$(".property-section.border").prop("hidden", false);
			$("#control-section").removeClass("col-lg-12");
			$("#control-section").addClass("col-lg-9");
			// let dataSourceFilter: object[] = [];
			// try {
			//     (dataSourceFilter as any) = (dataSource as Object[]).reduce((acc, cur) => {
			//         if (!(acc as any).find((doc) => doc?.Id === (cur as any)?.RoomId)) {
			//             (acc as any).push(cur);
			//         }
			//         return acc;
			//     }, []);
			// } catch (error) {
			//     console.error(error);
			// }
			//  $('#scheduler').empty()
			this.setData(dataSource, new Date());
		}
	}
	new_shift() {
		$('input[data-fieldname="id_shift"]').prop("value", "");
		$('input[data-fieldname="shift"]').prop("value", "");
		$('input[data-fieldname="base_date"]').prop("value", "");
		this.setData([], new Date());
	}
	async save_shift(): Promise<void> {
		const data = this.loadData();
		
		if (!data.length) {
			// @ts-ignore
			frappe.throw("The shift registration is empty");
		}
		// fitrar data - retirar datas iguais -
		
		const codata = (data as any).flat();
		let filterData: any[] = [];
		let seenStartTimes = new Set();
		codata.forEach((item: { StartTime: any; Id: string | number; EndTime: any; }) => {
			let startId = `${frappe.datetime.obj_to_str(item.StartTime)}-${
				item.Id
			}-${frappe.datetime.obj_to_str(item.EndTime)}`;
			if (!seenStartTimes.has(startId)) {
				seenStartTimes.add(startId);
				if (!filterData[item.Id]) {
					filterData[item.Id] = [];
				}
				filterData[item.Id].push(item);
			}
		});		

		const description = $('input[data-fieldname="shift"]').prop(
			"value"
		) as string;
		const id_shift =
			$('input[data-fieldname="id_shift"]').prop("value") || "";
				
		if (!description) {
			(frappe as any).throw("The shift name is empty");
		}

		let shift_planner_insert: any[] = [];
		filterData.map((doc) => {
			let child = filterData[doc[0].Id].map((doc: { Id: any; Subject: any; Description: any; IsAllDay: any; Location: any; StartTime: any; EndTime: any; Name: any; ResourceId: any}) => {
				return {
					id: doc.Id,
					subject: doc.Subject,
					description: doc.Description,
					isallday: doc.IsAllDay,
					location: doc.Location,
					start_time: frappe.datetime.obj_to_str(doc.StartTime),
					end_time: frappe.datetime.obj_to_str(doc.EndTime),
					name : doc.Name,
					resourceid : doc.ResourceId
				};
			});
			shift_planner_insert.push({
				doctype: "Shift planned",
				id: doc[0].Id,
				subject: doc[0].Subject,
				description: doc[0].Description,
				recurrenceid: doc[0].RecurrenceId,
				recurrencerule: doc[0].RecurrenceRule,
				roomid: doc[0].RoomId,
				isallday: doc[0].IsAllDay,
				location: doc[0].Location,
				start_time: frappe.datetime.obj_to_str(doc[0].StartTime),
				end_time: frappe.datetime.obj_to_str(doc[0].EndTime),
				details: child,
				name: doc[0].Name,
				resourceid: doc[0].ResourceId,
			});			
		});		
		
		let baseDateValue = $('input[data-fieldname="base_date"]').prop("value") as string | undefined;

		if (!baseDateValue) {
			return frappe.throw("Base Date is required");			
		}

		let shift_insert = {
			doctype: "Shift",
			description: $('input[data-fieldname="shift"]').prop("value") as string,
			name: id_shift,
			base_date: moment(baseDateValue, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"),
			event_limit: 0,
		};
		
		const response = await frappe.call(
			"nxerp.nxiot.page.shift_registration.shift_registration.update_shift",
			{
				data: JSON.stringify(shift_insert),
				shift_planner: JSON.stringify(shift_planner_insert),
			}
		);	
			
		const result = await this.fetchShiftData(response.message.name);
		
		this.setData(result.child_data, result.base_date);

		if (response.message) {
			frappe.msgprint({
				title: "Success",
				message: "Shift saved successfully",
				indicator: "green",
			});			
		};
		
	}
	async delete_shift(): Promise<void> {
		let id_shift = $('input[data-fieldname="id_shift"]').prop("value") as string;
		
		if (!id_shift) {
			// @ts-ignore
			return frappe.throw("The shift registration is empty");
		} // @ts-ignore
		return frappe.confirm(
			"Are you sure you want to delete this shift?",
			async () => {
				// @ts-ignore
				const deleted = await frappe.call(
					"nxerp.nxiot.page.shift_registration.shift_registration.delete_shift",
					{
						shift_name: id_shift,
					}
				);

				if (deleted.message = "success") {
					frappe.msgprint({
						title: "Success",
						message: "Shift deleted successfully",
						indicator: "green",
					});
					
					this.new_shift();
				}
				
				return true;
			},
			() => {
				return false;
			}
		);
	}
	async fetchShiftData(shiftName: string) {
		try {
			const response = await frappe.call({
				method: "nxerp.nxiot.page.shift_registration.shift_registration.get_shift_planned",
				args: { shift_name: shiftName }
			});
			const { shift, shift_planned } = response.message;
	
			console.log(shift);
			const child_data = shift_planned.map((doc: any) => {
				return {
					Subject: doc.subject,
					StartTime: new Date(doc.start_time),
					EndTime: new Date(doc.end_time),
					Id: doc.id,
					Location: doc.location,
					Description: doc.description,
					IsAllDay: doc.isallday,
					RecurrenceRule: doc.recurrencerule,
					RoomId: doc.roomid,
					Name: doc.name,
					ResourceId : doc.resourceid
				};
			});
	
			$('input[data-fieldname="id_shift"]').prop("value", shiftName);
			$('input[data-fieldname="shift"]').prop("value", shift.description);
			$('input[data-fieldname="base_date"]').prop(
				"value",
				moment(shift.base_date).format("DD/MM/YYYY")
			);
	
			return {
				child_data: child_data,
				base_date: shift.base_date,
			};
		} catch (error) {
			console.error("Error fetching shift data:", error);
			throw error;
		}
	}
	async select_shift(): Promise<void> {
		let promise_dialog = new Promise((resolve, reject) => {
			let dialog: any = new (frappe as any).ui.Dialog({
				title: "Select Shift",
				fields: [
					{
						fieldname: "shift",
						fieldtype: "Link",
						options: "Shift",
						label: "Shift",
						reqd: 1,
					},
				],
				primary_action_label: "Select",
				primary_action: async (value: { shift: string }) => {
					dialog.hide();
					try {
						const result = await this.fetchShiftData(value.shift);
						resolve(result);
					} catch (error) {
						reject(error);
					}
				},
			});
			dialog.show();
		});
	
		promise_dialog.then((doc) => {
			this.setData((doc as any).child_data, (doc as any).base_date);
		}).catch((error) => {
			console.error("Error in selectShift:", error);
		});
	}
	setData(data: object[], date: Date) {
		console.log("data", data);
		this.cleanscheduler();
		this.loadScheduler(data, date);
		dataEvents = [];
		dataEvents.push(data);
		dataSource = [];
		dataSource.push(data);
	}
	setDataResource(data: object[], data_machine: object[]) {
		console.log("data", data);
		this.cleanscheduler();
		this.loadSchedule(data, new Date(), data_machine);
		dataSource = [];
	}
}
// @ts-ignore
(frappe as any).provide("frappe.shift");
// @ts-ignore
(frappe as any).shift.ShiftPlanner = ShiftPlanner;

export default ShiftPlanner;

// custom_app/custom_app/public/js/frontend_expressions.js

let is_setting_value = false;
let is_setting_value_child = false;

frappe.db.get_list("DocType", {
    filters: { istable: 0 }, // Filtra apenas Doctypes que não são tabelas filhas
    fields: ["name"],        // Seleciona apenas o campo 'name' para otimizar a consulta
    limit: null
}).then((res) => {
    for (const doc of res) {
        frappe.ui.form.on(doc.name, {
            refresh(frm) {                   
                addGenericFieldListeners(frm);
                addChildTableFieldListeners(frm);
            }
        })
    }
})

function desabilitarTeclado(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
}

function addGenericFieldListeners(frm) {                  
    // Itera sobre todos os campos do formulário
    $.each(frm.fields_dict, function(fieldname, field) {
        let df = field.df;
        if (df.real_time_update) {
            if(df.fieldtype != "Data"){ 
                frappe.ui.form.on(frm.doctype, {
                    [df.fieldname] : async function (frm) {  
                        if (frm.is_processing) return;

                        frm.is_processing = true;
                        try {                        
                            console.time('Tempo de Execução Pai');
                            document.addEventListener('keydown', desabilitarTeclado, true);
                            document.addEventListener('keypress', desabilitarTeclado, true);
                            document.addEventListener('keyup', desabilitarTeclado, true);

                            console.log(`Disparo do Onchange Pai. Campo ${df.fieldname} atualizado.`);         
                            if (df.fetch_from) {    
                                await new Promise(resolve => setTimeout(resolve, 25));
                            }
                            await evaluateFrontendExpressionsDocPai(frm, df);

                        } catch (error) {
                            frm.is_processing = false;
                            document.removeEventListener('keydown', desabilitarTeclado, true);
                            document.removeEventListener('keypress', desabilitarTeclado, true);
                            document.removeEventListener('keyup', desabilitarTeclado, true);
                        } finally {
                            frm.is_processing = false;
                            document.removeEventListener('keydown', desabilitarTeclado, true);
                            document.removeEventListener('keypress', desabilitarTeclado, true);
                            document.removeEventListener('keyup', desabilitarTeclado, true);
                            console.timeEnd('Tempo de Execução Pai');
                        }
                    }
                })
            }
            else {
                let $input = frm.fields_dict[fieldname].$input;

                if ($input) {
                    // Remove handlers existentes para evitar duplicatas
                    //COMENTADO pois inicialmente não foi verificada a necessidade de remover changes, visto que o on_change quando colocado no pai roda depois.
                    // $input.off('change');

                    $input.on('change', async function() {  
                        if (frm.is_processing) return;

                        frm.is_processing = true;
                        try {                        
                            console.time('Tempo de Execução Pai');
                            document.addEventListener('keydown', desabilitarTeclado, true);
                            document.addEventListener('keypress', desabilitarTeclado, true);
                            document.addEventListener('keyup', desabilitarTeclado, true);

                            console.log(`Disparo do Onchange Pai. Campo ${df.fieldname} atualizado.`);         
                            
                            if (df.fetch_from) {    
                                await new Promise(resolve => setTimeout(resolve, 25));
                            }

                            //ATUALIZAR O VALOR DO CAMPO
                            //O Evento pode vir trazendo a informação anterior no frm, para garantir, sempre atualiza
                            let newValue =  $(this).val();

                            let nome =  $(this).attr('data-fieldname'); 

                            if (!frm.doc.hasOwnProperty(nome)) {
                                frm.doc[nome] = null;
                            }

                            frm.set_value(nome, newValue);
                            frm.doc[nome] = newValue;  
                            //TERMINAR DE ATUALIZAR O VALOR DO CAMPO
                            //O Evento pode vir trazendo a informação anterior no frm, para garantir, sempre atualiza 

                            await evaluateFrontendExpressionsDocPai(frm, df);

                        } catch (error) {
                            frm.is_processing = false;
                            document.removeEventListener('keydown', desabilitarTeclado, true);
                            document.removeEventListener('keypress', desabilitarTeclado, true);
                            document.removeEventListener('keyup', desabilitarTeclado, true);
                        } finally {
                            frm.is_processing = false;
                            document.removeEventListener('keydown', desabilitarTeclado, true);
                            document.removeEventListener('keypress', desabilitarTeclado, true);
                            document.removeEventListener('keyup', desabilitarTeclado, true);
                            console.timeEnd('Tempo de Execução Pai');
                        }                    
                    }) 
                }     
            }
        }
    });
}

function addChildTableFieldListeners(frm) {
    // debugger
    // Itera sobre todos os campos do formulário
    $.each(frm.fields_dict, function (fieldname, field) {
        // Verifica se o campo é uma tabela filha
        if (field.df.fieldtype === 'Table') {
            const grid = field.grid;
            // Garante que a tabela filha está corretamente configurada
            if (grid) {
                // Itera sobre os campos da tabela filha
                frappe.meta.get_docfields(field.df.options).forEach(childField => {
                    // Verifica se o campo possui o atributo 'real_time_update' para modificar o evento onchange
                     if (childField.real_time_update ) {
                        //Criação do evento on_change pro campo    
                        frappe.ui.form.on(grid.doctype, {
                            [childField.fieldname]: async function(frm, cdt, cdn) {
                                if (frm.is_processing) return;

                                frm.is_processing = true;
                                try {                        
                                    console.time('Tempo de Execução Filho');
                                    document.addEventListener('keydown', desabilitarTeclado, true);
                                    document.addEventListener('keypress', desabilitarTeclado, true);
                                    document.addEventListener('keyup', desabilitarTeclado, true);              
                                    // debugger
                                    if (childField.fetch_from) {
                                        await new Promise(resolve => setTimeout(resolve, 25));                                        
                                    }
                                    // Guarda a informação do doc do filho e do nome do campo que foi alterado
                                    const rowDoc= locals[cdt][cdn];
                                    
                                    //Chama as rotinas que irão chamar a API e autalizar o front. 
                                    //Tem tratativa para esperar a resposta assíncrona antes de continuar, para evitar rodar eventos simultâneos.    
                                    console.log(`Disparo do Onchange Filho. Campo ${childField.fieldname} atualizado na linha ${rowDoc.name}`);
                                    await evaluateFrontendExpressionsDocFilho(rowDoc, frm, childField); 
                                    await evaluateFrontendExpressionsDocPai(frm);  
                                } catch (error) {
                                    frm.is_processing = false;
                                    document.removeEventListener('keydown', desabilitarTeclado, true);
                                    document.removeEventListener('keypress', desabilitarTeclado, true);
                                    document.removeEventListener('keyup', desabilitarTeclado, true);
                                } finally {
                                    frm.is_processing = false;
                                    document.removeEventListener('keydown', desabilitarTeclado, true);
                                    document.removeEventListener('keypress', desabilitarTeclado, true);
                                    document.removeEventListener('keyup', desabilitarTeclado, true);
                                    console.timeEnd('Tempo de Execução Filho');
                                }                                
                            }
                        });  
                    }            
                });

                //Adiciona evento da inserçãode uma linha 
                frappe.ui.form.on(grid.doctype, {
                    [grid.df.fieldname + '_add']: function(frm) {
                        console.log(`Disparo do Onchange Adição de linha no Filho.`)
                        evaluateFrontendExpressionsDocPai(frm); 
                    }
                });

                //Adiciona evento da remoção de uma linha 
                frappe.ui.form.on(grid.doctype, {
                    [grid.df.fieldname + '_remove']: function(frm) {
                        console.log(`Disparo do Onchange Remoção de linha no Filho.`)
                        evaluateFrontendExpressionsDocPai(frm); 
                    }
                });
            }
        }
    });
}

async function evaluateFrontendExpressionsDocPai(frm, field_onchange = null) {
    // debugger
    try {
        console.time('Tempo de Execução Backend Pai');
        // Chama a API do backend e aguarda a conclusão
        const r = await frappe.call({
            method: "nxerp.utils.validate_calc_personalizado.evaluate_expression_Impostos",
            args: {
                doc: frm.doc,             
                field_onchange: field_onchange
            }
        });
        console.timeEnd('Tempo de Execução Backend Pai');
        if (r.message) {
            if (r.message.error) {
                frappe.msgprint(`Erro na expressão personalizada em ${field.label}: ${r.message.error}`);
            } else {
                if (!is_setting_value) { // Certifique-se de que 'is_setting_value' está definido no escopo apropriado
                    is_setting_value = true;

                    // LOGICA 1
                    let matchingFields = null;
                    let hasFormulaField = null;
                    
                    const nome = field_onchange?.fieldname;
                    // ** NOVO CÓDIGO **: Verifica tabelas filhas
                    if (nome) {
                        frm.fields.forEach((field) => {
                            if ('df' in field) {
                                if (field.df.fieldtype === 'Table') {
                                    // Obtém os metadados do Doctype da tabela filha
                                    let childFields = frappe.meta.get_docfields(field.df.options);
                                    
                                    if (!hasFormulaField) {
                                        // Verifica se algum campo tem `formula_field` contendo `parent.<nomeAlterado>`
                                        hasFormulaField = childFields.some(df => df.formula_field && df.formula_field.includes(`parent.${nome}`));
                                    } 

                                    if (hasFormulaField && !matchingFields) {
                                        // console.log(`Campo com fórmula detectado no Doctype filho: ${field.df.options}`);
                                        // Filtrar os campos que contêm 'parent.${nome}' em 'formula_field' e mapear os 'fieldname'
                                        matchingFields = childFields.filter(df => 
                                            df.formula_field && df.formula_field.includes(`parent.${nome}`)
                                        );
                                    }
                                }
                            }
                        }); 
                    }

                    Object.keys(frm.fields_dict).forEach((fieldname) => {
                        let field = frm.fields_dict[fieldname];
                        
                        // if (field.df.fieldtype !== 'Table') {
                        if (['Float', 'Int', 'Currency','Data', 'Text', 'Small Text', 'Link'].includes(field.df.fieldtype)) {
                            let valorAtual = frm.doc[fieldname];
                            let novoValor = r.message ? r.message[fieldname] : null; // Ajuste conforme a estrutura da resposta

                            // Verifica se o novo valor existe e é diferente do valor atual
                            if (novoValor !== null && valorAtual !== novoValor) {
                                frm.set_value(fieldname, novoValor);
                                // Não é necessário atribuir diretamente ao frm.doc, frm.set_value já atualiza o documento
                                console.log(`Atualização DocPai. Campo ${fieldname} atualizado com o resultado: ${novoValor}`);
                                
                                if (!hasFormulaField) {
                                    frm.fields.forEach((field) => {
                                        if ('df' in field) {
                                            if (field.df.fieldtype === 'Table') {
                                                // Obtém os metadados do Doctype da tabela filha
                                                let childFields = frappe.meta.get_docfields(field.df.options);
                                        
                                                // Verifica se algum campo tem `formula_field` contendo `parent.<nomeAlterado>`
                                                hasFormulaField = childFields.some(df => df.formula_field && df.formula_field.includes(`parent.${fieldname}`));                                        

                                                if (hasFormulaField) {
                                                    // console.log(`Campo com fórmula detectado no Doctype filho: ${field.df.options}`);
                                                    // Filtrar os campos que contêm 'parent.${nome}' em 'formula_field' e mapear os 'fieldname'
                                                    matchingFields = childFields.filter(df => 
                                                        df.formula_field && df.formula_field.includes(`parent.${fieldname}`)
                                                      );
                                                }
                                            }
                                        }
                                    }); 
                                }
                            }
                        }
                    });     

                    if (hasFormulaField) {
                        // console.log(`Campo com fórmula detectado no Doctype filho: ${field.df.options}`);
                        frm.fields.forEach((field) => {
                            if ('df' in field) {
                                if (field.df.fieldtype === 'Table') {
                                    // Agora é seguro iterar sobre as linhas da tabela filha
                                    (frm.doc[field.df.fieldname] || []).forEach((row) => {
                                        // console.log(`Atualizando linha do filho: ${row.name}`);
                                        // Chama a função para atualizar a linha do filho
                                        evaluateFrontendExpressionsDocFilho(row, frm, matchingFields[0]);
                                    });
                                }
                            }
                        });
                    }

                    is_setting_value = false;
                    
                    // console.log(`Atualização DocPai. Campo ${field.fieldname} atualizado com o resultado: ${r.message.result}`);
                }
            }
        }
    } catch (err) {
        frappe.msgprint(`Erro na chamada do backend para ${field.label}: ${err.message}`);
        // console.error(`Erro na chamada do backend:`, err);
        is_setting_value = false;
    }
}

async function evaluateFrontendExpressionsDocFilho(rowDoc, docPai, field_onchange) {
    try {
        console.time('Tempo de Execução Backend Filho');
        // Chama a API do backend e aguarda a conclusão
        const r = await frappe.call({
            method: "nxerp.utils.validate_calc_personalizado.evaluate_expression_Impostos",
            args: {
                doc: rowDoc,
                docPai: docPai.doc,
                field_onchange: field_onchange
            }
        });
        console.timeEnd('Tempo de Execução Backend Filho');
        if (r.message) {
            if (r.message.error) {
                frappe.msgprint(`Erro na expressão personalizada em ${field.label}: ${r.message.error}`);
            } else {
                if (!is_setting_value) { // Certifique-se de que 'is_setting_value' está definido no escopo apropriado
                    is_setting_value = true;

                    // Obtém a linha da tabela filha no grid
                    const grid_row = cur_frm.fields_dict[rowDoc.parentfield].grid.get_row(rowDoc.name);

                    if (grid_row) {
                        // Obtenha os campos da child table DocType
                        let child_doctype = rowDoc.doctype;
                        let fields = frappe.meta.get_docfields(child_doctype);
                                                
                        for (const field of fields) {
                            try {
                                if (field.fieldtype !== 'Table') {
                                    let fieldname = field.fieldname;
                                    let valorAtual = rowDoc[fieldname];
                                    
                                    // Acessa o novo valor da resposta. Ajuste conforme a estrutura da resposta
                                    let novoValor = r.message ? r.message[fieldname] : null;
                                    
                                    // Verifica se o novo valor existe e é diferente do valor atual
                                    if (novoValor !== null && valorAtual !== novoValor) {
                                        rowDoc[fieldname] = novoValor;
                                        // // Nenhuma necessidade de atribuição direta a rowDoc[fieldname]
                                        grid_row.refresh_field(fieldname);
                                        console.log(`Atualização DocFilho. Campo ${fieldname} atualizado com o resultado: ${novoValor}`);
                                    }
                                }
                            }
                            catch (err) {
                                is_setting_value = false;
                                frappe.throw(`Erro na chamada do backend (Final) para ${field.fieldname}: ${err.message}`);                                
                            }
                        };
                    }                     
                    is_setting_value = false;
                    
                }
            }
        }
    } catch (err) {
        is_setting_value = false;
        frappe.msgprint(`Erro na chamada do backend (Inicio) para ${field_onchange.label}: ${err.message}`);      
    }
}

// global.d.ts
declare let frappe: any;

const utils = {

    /**
     * Combina a data de `date` com a hora de `time` para criar uma nova data.
     *
     * @function convergeDate
     * @param {Date | null} date - A data que fornece o ano, mês e dia para a nova data. Se `null`, a função retornará `null`.
     * @param {Date | null} time - A data que fornece a hora, minuto e segundo para a nova data. Se `null`, a função retornará `null`.
     * @returns {Date | null} - Uma nova data combinando a data de `date` com a hora de `time`, ou `null` se qualquer um dos parâmetros for `null`.
     * 
     * @example
     * const date = new Date('Mon Sep 16 2024 13:30:00 GMT-0300');
     * const time = new Date('Mon Sep 19 2024 18:20:00 GMT-0300');
     * const newDate = convergeDate(date, time);
     * console.log(newDate.toString()); // Output: Mon Sep 16 2024 18:20:00 GMT-0300
     */
    convergeDate: (date: Date | null, time: Date | null): Date | null => {
        if (!date || !time) {
            return null;
        }
        try {
            // Extraí as partes da data e hora
            let dateYear = date.getFullYear();
            let dateMonth = date.getMonth();
            let dateDay = date.getDate();
            let timeHours = time.getHours();
            let timeMinutes = time.getMinutes();
            let timeSeconds = time.getSeconds();
    
            // Cria e retorna uma nova data
            return new Date(dateYear, dateMonth, dateDay, timeHours, timeMinutes, timeSeconds);
        } catch (error) {
            console.error('convergeDate', error);
            return null;
        }
    }
};

(frappe as any).provide("frappe.nxerp");
(frappe as any).nxerp.utils = utils;
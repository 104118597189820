frappe.provide("nxerp.ui.toolbar");

document.addEventListener("DOMContentLoaded", function () {
    // Cria um evento customizado para o login
    const checkDefaultCompanyEvent = new Event('checkDefaultCompany');

    if (!sessionStorage.getItem('hasCalledCheckDefaultCompany')) {
        document.addEventListener('checkDefaultCompany', function () {
            nxerp.ui.toolbar.check_default_company_and_prompt();
            sessionStorage.setItem('hasCalledCheckDefaultCompany', 'true');
        });

        document.dispatchEvent(checkDefaultCompanyEvent);

    }
});

nxerp.ui.toolbar.check_default_company_and_prompt = async () => {
    try{
        const count_companies_enabled = await frappe.db.count('Company', { disabled: 1 });
        if (count_companies_enabled > 1) {

                await nxerp.ui.toolbar.company_settings_default();
        
        }

    } catch (err) {
        console.log(err)
        frappe.msgprint(__('Erro ao verificar a empresa padrão: ') + err);
    }
};

nxerp.ui.toolbar.company_settings_default = async () => {
    

    const companies = (await frappe.call('nxerp.public.py.toolbar.get_active_companies')).message
    if (!companies.length) {
        frappe.msgprint(__('Nenhuma empresa ativa encontrada.'));
        return;
    }
    // console.log(companies)
    let dialog = new frappe.ui.Dialog({
        title: 'Selecione uma Empresa',
        fields: [{
            fieldtype: 'Table',
            fieldname: 'company_table',
            cannot_add_rows: true,
            in_place_edit: false,
            data: companies.map(company => ({
                entity_name: company.entity_name,
                name: company.name
            })),
            get_data: () => {
                return companies.map(company => ({
                    entity_name: company.entity_name
                }));
            },
            fields: [{
                fieldtype: 'Data',
                fieldname: 'entity_name',
                label: 'Empresa',
                in_list_view: true,
                read_only: 1
            }]
        }]
    });
    
    setTimeout(() => {
        
        dialog.show();
        dialog.$wrapper.find('.btn-open-row').closest('.col').hide();
        dialog.$wrapper.find('[data-fieldname="entity_name"]').addClass('disabled').css('pointer-events', 'none');
        dialog.$wrapper.find('.row-check').closest('.col').hide();
        
        dialog.$wrapper.find('.grid-row').on('click', async (grid) => {
            // console.log($(grid.currentTarget).attr('data-name'))
            let company = $(grid.currentTarget).attr('data-name');
            console.log('company',company)
            if (company) {
                    const set_default_company = await frappe.call('nxerp.public.py.toolbar.set_default_company', {"company_name": company})
                    // const global = (await frappe.db.get_value('Global Defaults', null, 'default_company')).message;
                    // const global_value = global ? global.company : '';
                    // if (global_value && global_value !== set_default_company.message) {
                    //     location.reload();
                    // }
                    dialog.hide();
            }
        });
    }, 1000);

   
};


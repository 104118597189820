frappe.provide('frappe.nxerp.utils');

const utils = {
    /**
     * Retorna uma string contendo apenas os números da string recebida por parâmetro.
     * @param {string} str - A string a ser processada.
     * @returns {string} - A string contendo apenas os números.
     */
    get_string_numbers: (str = '') => str.replace(/[^\d]/g, ''),

    round_values: (values = [], digits = 2) => {
        console.log('Executando método "round_values"...');
        console.log("Input (values):", values);
        console.log("Input (digits):", digits);
    
        const rounded_values = [];
        let value_diff = 0;
    
        values.forEach(value => {
            const number_value = typeof value === 'string' ? Number(utils.get_string_numbers(value)) : value;
            const rounded = Number(number_value.toFixed(digits));
            value_diff += value - rounded;
            rounded_values.push(rounded);
        });
    
        if (rounded_values.length > 0) {
            const lastIndex = rounded_values.length - 1;
            rounded_values[lastIndex] = Number((rounded_values[lastIndex] + value_diff).toFixed(digits));
        }
    
        console.log("Output:", rounded_values);
        return rounded_values;
    },

    validate: {
        cnpj: (cnpj = '') => {
            const cnpj_numbers = utils.get_string_numbers(cnpj);
            if (cnpj_numbers === '') {
                return {
                    status: 400,
                    code: 101,
                    message: 'Necessário digitar CNPJ!',
                    cnpj: cnpj,
                };
            }

            if (cnpj_numbers.length !== 14) {
                return {
                    status: 400,
                    code: 102,
                    message: 'O CNPJ deve conter 14 dígitos!',
                    cnpj: cnpj,
                };
            }

            const regex = /^(\d)\1+$/; // Regex para verificar se há apenas dígitos repetidos
            if (
                regex.test(cnpj_numbers) ||
                !utils.validate.cnpj_dv(cnpj_numbers)
            ) {
                return {
                    status: 400,
                    code: 103,
                    message: 'CNPJ inválido!',
                    cnpj: cnpj,
                };
            }

            return {
                status: 200,
                code: 0,
                message: 'CNPJ válido!',
                cnpj: cnpj,
            };
        },
        cnpj_dv: (cnpj = '') => {
            cnpj = utils.get_string_numbers(cnpj);
            const size = cnpj.length - 2;
            const nums = cnpj.substring(0, size);
            const digits = cnpj.substring(size);

            // Mudança começa aqui
            // Verificação do primeiro dígito verificador
            let sum = 0;
            let pos = size - 7;
            for (let i = size; i >= 1; i--) {
                sum += parseInt(nums.charAt(size - i)) * pos--;
                if (pos < 2) pos = 9;
            }

            let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (result != parseInt(digits.charAt(0))) {
                return false;
            }

            // Verificação do segundo dígito verificador
            sum = 0;
            pos = size - 6;
            for (let i = size + 1; i >= 1; i--) {
                sum += parseInt(cnpj.charAt(size + 1 - i)) * pos--;
                if (pos < 2) pos = 9;
            }

            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (result != parseInt(digits.charAt(1))) {
                return false;
            }
            // Mudança termina aqui

            return true;
        },

        // cnpj_dv: (cnpj = '') => {
        //     cnpj = utils.get_string_numbers(cnpj);
        //     const size = cnpj.length - 2;
        //     const nums = cnpj.substring(0, size);
        //     const digits = cnpj.substring(size);
        //     let sum = 0;
        //     let pos = size - 7;

        //     for (let i = size; i >= 1; i--) {
        //         sum += parseInt(nums.charAt(size - i)) * pos--;
        //         if (pos < 2) pos = 9;
        //     }

        //     let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        //     if (result != digits.charAt(0) && result != digits.charAt(1)) {
        //         return false;
        //     }
        //     return true;
        // },
    },

    format: {
        /**
         * Recebe um CNPJ e o retorna formatado corretamente (XX.XXX.XXX/XXXX-XX).
         * @param {string} cnpj - O CNPJ a ser formatado.
         * @returns {string} - O CNPJ formatado.
         */
        cnpj: (cnpj = '') =>
            utils
                .get_string_numbers(cnpj)
                .replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
                    '$1.$2.$3/$4-$5',
                ),

        /**
         * Recebe um CPF e o retorna formatado corretamente (XXX.XXX.XXX-XX).
         * @param {string} cpf - O CPF a ser formatado.
         * @returns {string} - O CPF formatado.
         */
        cpf: (cpf = '') =>
            utils
                .get_string_numbers(cpf)
                .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4'),

        /**
         * Recebe um CEP e o retorna formatado corretamente (XXXXX-XXX).
         * @param {string} cep - O CEP a ser formatado.
         * @returns {string} - O CEP formatado.
         */
        cep: (cep = '') =>
            utils.get_string_numbers(cep).replace(/^(\d{5})(\d{3})$/, '$1-$2'),

        /**
         * Recebe uma string e a retorna sem acentos e com traços no lugar de espaços.
         * @param {string} city - O nome da cidade a ser formatado.
         * @returns {string} - O nome da cidade formatado.
         */
        city: (city = '') =>
            city
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/\s/g, '-'),
    },
};

window.addEventListener('load', function () {
    frappe.realtime.on('toast', (params) => {
        console.log('Executando evento de toast\nParâmetros recebidos:', params);

        const indicator = params.indicator || 'blue';
        const message = params.message || 'Defina a mensagem do alerta na chave "message"';
        const seconds = params.seconds || 5;
        const user = params.user || frappe.session.user;
        const to_all_users = params.to_all_users || false;

        if (!to_all_users && user !== frappe.session.user) return;

        return frappe.toast({
            indicator,
            message,
        }, seconds);
    })
});

frappe.nxerp.utils.utils = utils;
